<template>
  <data-table
    ref="table"
    :actions="isUserInternalAdmin ? actions : []"
  />
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import mixin from './mixin'

const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth')

export default {
  name: 'Persons',
  mixins: [mixin],
  data() {
    return {
      actions: [
        {
          event: 'internal/showFormPersonUpdate',
          name: 'Edit',
        },
        {
          event: 'internal/showFormPersonDelete',
          name: 'Delete',
        },
        {
          event: 'internal/goToPerson',
          name: 'View Profile',
        },
      ],
    }
  },
  computed: {
    ...mapAuthGetters(['isUserInternalAdmin']),
  },
}
</script>
